import React from 'react'
import { Link } from 'gatsby'

import DefaultLayout from '../layouts'
import Image from '../components/image'
import SEO from '../components/seo'

const IndexPage = () => (
  <DefaultLayout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <h1>Welcome to my new homepage</h1>
    <p />
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image imageName="mweeks-selfie.jpg" />
    </div>
  </DefaultLayout>
)

export default IndexPage
